import React from "react"
import styles from "./container.module.css"
import * as classNames from "classnames"

const Container = ({ className, children }) => {
  const containerClasses = classNames(styles.container, className)

  return <div className={containerClasses}>{children}</div>
}

export default Container
