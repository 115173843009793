import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import icon180 from "./assets/apple-touch-icon-180x180.png"
import icon32 from "./assets/favicon-32x32.png"
import iconPinnedTab from "./assets/safari-pinned-tab.png"
import { colorBlack } from "../colors/colors.css"

const Meta = ({ title, description, image }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description
  const metaImage = `${site.siteMetadata.url}${image}`

  return (
    <Helmet>
      <title>
        {title
          ? `${title} · ${site.siteMetadata.title}`
          : site.siteMetadata.title}
      </title>

      <link rel="apple-touch-icon" sizes="180x180" href={icon180} />
      <link rel="icon" type="image/png" sizes="32x32" href={icon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={icon32} />
      <link rel="mask-icon" href={iconPinnedTab} color={colorBlack} />
      <meta name="apple-mobile-web-app-title" content="MAD" />
      <meta name="msapplication-TileColor" content={colorBlack} />
      <meta name="theme-color" content={colorBlack} />
      <meta name="description" content={metaDescription} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:width" content="1200" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta property="twitter:image" content={metaImage} />
      <meta name="twitter:card" content="summary_large_image" />

      <meta
        name="keywords"
        content={`${title}, Design System, Canva, Product Design`}
      />
    </Helmet>
  )
}

Meta.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

Meta.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

export default Meta
