import React from "react"
import styles from "./header.module.css"
import { Link } from "gatsby"
import Container from "components/container/container"
import Logo from "components/logo/logo"

const Header = () => (
  <header className={styles.header}>
    <Container className={styles.container}>
      <Link to="/">
        <Logo />
      </Link>
      <nav>
        <ul className={styles.list}>
          <li key={1} className={styles.listItem}>
            <Link to="/">Home</Link>
          </li>
          <li key={2} className={styles.listItem}>
            <a href="https://twitter.com/mattanddesign">Twitter</a>
          </li>
        </ul>
      </nav>
    </Container>
  </header>
)

export default Header
