import React from "react"
import PropTypes from "prop-types"
import Container from "components/container/container"
import Main from "components/main/main"
import Header from "components/header/header"
import "components/normalize/normalize.css"
import "./layout.css"

const Layout = ({ children }) => (
  <>
    <Header />
    <Container>
      <Main>{children}</Main>
    </Container>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
